<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="8" offset-md="2" cols="12">
          <v-card v-if="logged_user !== null">
            <v-card-title>
              <v-row class="px-2" justify="space-between">
                <span>Profil de {{ logged_user.fullname }}</span>
                <v-btn @click="openDrawer" color="primary">
                  <v-icon>mdi-pencil</v-icon>
                  Modifier
                </v-btn>
              </v-row>

            </v-card-title>
            <v-card-text>
              <h4>Nom complet</h4>
              <p>{{ logged_user.fullname }}</p>

              <h4>Nom d'utilisateur</h4>
              <p>{{ logged_user.username }}</p>


              <h4>E-mail</h4>
              <p>{{ logged_user.email }}</p>

              <h4>Groupe</h4>
              <p>{{ logged_user.group }}</p>

              <div v-if="logged_user.profile !== null">
                <h4>Téléphone fixe</h4>
                <p>{{ logged_user.profile.tel_fixe }}</p>

                <h4>Téléphone mobile</h4>
                <p>{{ logged_user.profile.tel_mobile }}</p>

                <h4>Métiers</h4>
                <p>
                  <v-chip v-for="metier in logged_user.profile.metiers" :key="metier.id" color="primary">
                    {{ metier.name }}
                  </v-chip>
                </p>
              </div>
              <div v-if="logged_user.profile !== null && logged_user.profile.prestataire !== null">
                <h4>Raison sociale</h4>
                <p>{{ logged_user.profile.prestataire.raison_sociale }}</p>

                <h4>Adresse</h4>
                <p>{{ logged_user.profile.prestataire.adresse }}</p>

                <h4>Code postal</h4>
                <p>{{ logged_user.profile.prestataire.code_postale }}</p>

                <h4>SIRET</h4>
                <p>{{ logged_user.profile.prestataire.siret }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NewIntervenantSidebar from "@/components/intervenants/NewIntervenantSidebar";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  mounted() {
    if (this.$store.getters["groups/groups"].length === 0) {
      this.$store.dispatch("groups/fetchGroups");
    }
  },
  name: "Profile",
  mixins: [DrawerMixin],
  components: {},
  data() {
    return {
      dialog: false,
      operation: "create",
      intervenantToUpdate: null,
      intervenant_id: -1
    }
  },
  computed: {
    logged_user() {
      return this.$store.getters["users/getLoggedUser"];
    },
    groups() {
      return this.$store.getters["groups/groups"];
    },
  },
  methods: {
    closeDrawer() {
      this.$store.dispatch("users/reloadUserFromSession");
      this.dialog = false;
      this.operation = "create";
      this.intervenantToUpdate = null;
      this.intervenant_id = -1;
    },
    openDrawer() {
      this.openDrawerDialog(NewIntervenantSidebar, {key: this.logged_user.id, operation: "update", groups: this.groups, intervenantToUpdate: this.logged_user})
    }
  },
}
</script>

<style scoped>

</style>